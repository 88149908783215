import './jquery-global.js';
import '../node_modules/jquery-validation/dist/jquery.validate.min.js';
import '../node_modules/jquery-validation/dist/localization/messages_fr.js';
import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import Blazy from "blazy";
import Slick from "slick-slider";

$(function() {

  const header = $('.navigation');
  const body = $('body');
  const hamburger = $('.hamburger');
  const navigation = $('.navigation--nav');
  const btn_top = $('.footer--to-top','.footer');

  // Is Mobile ?
  function checkMobile(){
    if (hamburger.css('display') == 'none') {
      hamburger.removeClass('is-active');
      body.removeClass('is-fixed');
      navigation.removeAttr('style');
    }
  }
  checkMobile(); // Au chargement de la page
  $(window).resize(checkMobile); // Au redimensionnement de la page

  // Bouton Hamburger
  $(hamburger, header).click(function(e) {
    e.preventDefault();
    hamburger.toggleClass('is-active');
    body.toggleClass('is-fixed');
    header.toggleClass('is-open-mobile');
  });

  // Header - Scrolled
  $(window).on("scroll", function() {
    if($(window).scrollTop() > 50) {
      header.addClass("is-scrolled");
      btn_top.addClass('is-scrolled');
    } else {
      header.removeClass("is-scrolled");
      btn_top.removeClass('is-scrolled');
    }
  });

  // Header - Scrolled (au chargement)
  if ($(this).scrollTop() >= 20) {
    header.addClass('is-scrolled');
  } else {
    header.removeClass('is-scrolled');
  }

  // Lazyload (Media)
  var bLazy = new Blazy({
    offset: 300,
    success: function(ele){
      // Image has loaded
      // Do your business here
    },error: function(ele, msg){
      if(msg === 'missing'){
     // Data-src is missing
      }
      else if(msg === 'invalid'){
      // Data-src is invalid
      }
    }
  });

  // Bouton - Haut de page
  btn_top.click(function(e){
    e.preventDefault();
    $('html, body').animate({ scrollTop : 0 }, 300);
  });

  // Slider - Testimonials
  $('.modular--testimonials__slider').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    arrows : false,
    rows: 0,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });

  // Fix bug slider with key arrow
  var $carousel = $('.modular--testimonials__slider');
  $(document).on('keydown', function(e) {
    if(e.keyCode == 37) {
      $carousel.slick('slickPrev');
    }
    if(e.keyCode == 39) {
      $carousel.slick('slickNext');
    }
  });

  $('.modular--testimonials__slider').on('init', function(event, slick){
    bLazy.revalidate();
  });

  $('.modular--testimonials__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    bLazy.revalidate();
  });

  // Formulaire de contact
  $(".contact-form").validate();

  // Obfuscation de lien (PR Sculpting)
  $('[data-obf]').each(function() {
    $(this).click(function(event) {
      let attribute = $(this).data('obf');
      let action = $(this).data('action');

      if (event.shiftKey || event.ctrlKey || event.metaKey || action === 'blank') {
        let newWindow = window.open(decodeURIComponent(window.atob(attribute)), '_blank');
        newWindow.focus();
      } else {
        document.location.href= decodeURIComponent(window.atob(attribute));
      }
    });
  });

  $(document).on("mousedown", "[data-obf]", function(event) {
    if( event.which == 2 ) {
      let attribute = $(this).data('obf');
      let newWindow = window.open(decodeURIComponent(window.atob(attribute)), '_blank');
      newWindow.focus();
    }
  });

  // Fit height textarea to content - Contact
  $('textarea').each(function() {
    let $this = $(this),
        minHeight = $this.height(),
        lineHeight = $this.css('lineHeight');

    let shadow = $('<div></div>').css({
      position: 'absolute',
      top: -10000,
      left: -10000,
      width: $(this).width() - parseInt($this.css('paddingLeft')) - parseInt($this.css('paddingRight')),
      fontSize: $this.css('fontSize'),
      fontFamily: $this.css('fontFamily'),
      lineHeight: $this.css('lineHeight'),
      resize: 'none'
    }).appendTo(document.body);

    let update = function() {
      let times = function(string, number) {
        let _res = '';
        for(let i = 0; i < number; i ++) {
            _res = _res + string;
        }
        return _res;
      };

      let val = this.value.replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/&/g, '&amp;')
        .replace(/\n$/, '<br/>&nbsp;')
        .replace(/\n/g, '<br/>')
        .replace(/ {2,}/g, function(space) { return times('&nbsp;', space.length -1) + ' ' });

      shadow.html(val);
      $(this).css('height', Math.max(shadow.height() + 20, minHeight));
    }
    $(this).css('overflow','hidden').css('resize','none').change(update).keyup(update).keydown(update);
    update.apply(this);
  });
})
